$(function () {
    $('[data-toggle="popover"]').popover();
});

var formatCurrency = function (number) {
    var formatter = new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(number);
};

$('#ihtCalculateButton').click(function () {

    var over2MillionNotice = $('#over2MillionNotice');
    var under2MillionNotice = $('#under2MillionNotice');
    var ihtTotals = $('#ihtTotals');
    over2MillionNotice.hide();
    under2MillionNotice.hide();
    ihtTotals.hide();

    var assetsTotal = 0;
    assetsTotal += Number($('#valueOfMainProperty').val());
    assetsTotal += Number($('#valueOfOtherProperty').val());
    assetsTotal += Number($('#valueOfOtherInvestments').val());
    assetsTotal += Number($('#valueOfCash').val());
    assetsTotal += Number($('#valueOfOtherAssets').val());
    $('#assetsTotal').text(formatCurrency(assetsTotal));

    var liabilitiesTotal = 0;
    liabilitiesTotal += Number($('#valueOfOutstandingMortgages').val());
    liabilitiesTotal += Number($('#valueOfOtherDebts').val());
    $('#liabilitiesTotal').text(formatCurrency(liabilitiesTotal));

    var handingDownToDirectDescendants = Number($('input[name="directDescendants"]:checked').val());

    var nilRateBand = 325000;
    if (handingDownToDirectDescendants === 1) {
        nilRateBand = 500000;
    }

    var maritalStatus = $('input[name="maritalStatus"]:checked').val();

    if (maritalStatus === 'married' || maritalStatus === 'widowed') {
        nilRateBand = nilRateBand * 2;
    }

    var estateTotal = assetsTotal - liabilitiesTotal;

    if (estateTotal >= 2000000) {
        over2MillionNotice.show();
        $('html, body').animate({
            scrollTop: over2MillionNotice.offset().top - $('#main-nav').outerHeight(),
        }, 500);
    } else {
        under2MillionNotice.show();
        var amountToPayTaxOn = estateTotal - nilRateBand;
        var amountOfIHT = 0;
        if (amountToPayTaxOn > 0) {
            amountOfIHT = amountToPayTaxOn * 0.4;
        }
        $('#amountOfIHT').text(formatCurrency(amountOfIHT));
        ihtTotals.show();
        $('html, body').animate({
            scrollTop: ihtTotals.offset().top - $('#main-nav').outerHeight(),
        }, 500);
    }
});

$('input[name="maritalStatus"]').change(function () {
    var maritalStatus = $('input[name="maritalStatus"]:checked').val();
    var maritalStatusMarriedExplanation = $('#maritalStatusMarriedExplanation');
    var maritalStatusWidowedExplanation = $('#maritalStatusWidowedExplanation');
    maritalStatusMarriedExplanation.addClass('d-none');
    maritalStatusWidowedExplanation.addClass('d-none');
    if (maritalStatus === 'married') {
        maritalStatusMarriedExplanation.removeClass('d-none');
    }
    if (maritalStatus === 'widowed') {
        maritalStatusWidowedExplanation.removeClass('d-none');
    }
});


$('#CloseCookiesNotice').click(function () {
    $('#CookieWarning').hide();
});

var getShareText = function () {
    return $('meta[property="og:title"]').attr('content');
};

$('.facebook-share').click(function () {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, '_blank');
});

$('.twitter-share').click(function () {
    var text = getShareText();
    window.open('https://twitter.com/intent/tweet?url=' + window.location.href + '&text=' + text, '_blank');
});

$('.linked-in-share').click(function () {
    var text = getShareText();
    window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href + '/&title=' + text + '&summary=&source=', '_blank');
});

var positionNavbarToggler = function () {
    var mainNav = $('#main-nav .container');
    var toggler = $('#main-navbar-toggler');
    var topPosition = (mainNav.outerHeight() / 2) - (toggler.outerHeight() / 2);
    toggler.css('top', topPosition + 'px');
};

$('.open-modal').click(function () {
    $('body').addClass('modal-open');
    var blockID = $(this).attr('data-block-id');
    $('#block-' + blockID + ' .bbp-modal').fadeIn('fast');
});

$('.bbp-modal__close').click(function () {
    $('body').removeClass('modal-open');
    var blockID = $(this).attr('data-block-id');
    $('#block-' + blockID + ' .bbp-modal').fadeOut('fast');
});

$('.play-video').click(function () {
    var blockID = $(this).attr('data-block-id');
    var videoSrc = $(this).attr('data-video-src');
    var iframe = $('#block-' + blockID + ' iframe');
    iframe.attr('src', videoSrc + '&autoplay=1');
});

$('.stop-video').click(function () {
    var blockID = $(this).attr('data-block-id');
    var iframe = $('#block-' + blockID + ' iframe');
    iframe.attr('src', '');
});

$('.team-members-team_member').click(function () {
    var teamMemberID = $(this).attr('data-team-member-id');
    var teamMemberPopup = $('#team-member-popup-' + teamMemberID);
    teamMemberPopup.addClass('visible');
    teamMemberPopup.fadeIn();
    $('html, body').animate({
        scrollTop: teamMemberPopup.offset().top - 90,
    }, 1000);
});

$('.team-members-block__popup-close').click(function () {
    $('.team-members-block__popup.visible').fadeOut();
});

var getTeamMembersPerLine = function () {
    var width = $(window).width();
    if (width < 767) {
        return 2;
    } else if (width < 991) {
        return 3;
    } else {
        return 4;
    }
};

var applyMarginClassesToTeamMembers = function () {

    $('.team-members-block').each(function () {
        var teamMembersBlock = $(this);

        var visibleTeamMembers = teamMembersBlock.find('.team-members-team_member:not(.hidden-position):not(.hidden-service)');
        var visibleTeamMembersLength = visibleTeamMembers.length;

        // work out how many per line there will be based on screen size
        var teamMembersPerLine = getTeamMembersPerLine();

        // Remove all positioning classes before reapplying
        visibleTeamMembers.removeClass('mr-0 ml-0 mr-auto ml-auto team-members-team_member--no-margin');

        // If the total number matches the number per line or is 1 we dont need to do anything
        if (visibleTeamMembersLength !== teamMembersPerLine || visibleTeamMembersLength !== 1) {
            // Get the last row of team members and apply positioning classes to the first and last of them
            var remainder = visibleTeamMembersLength % teamMembersPerLine;
            var lastRow = visibleTeamMembers.slice(-Math.abs(remainder));

            lastRow.addClass('team-members-team_member--no-margin');
            lastRow.first().addClass('mr-0 ml-auto');
            lastRow.last().addClass('ml-0 mr-auto');
        }

    });
};

var filterTeamMembersByService = function (filter, blockID) {

    // close all popups before filtering
    var blockIDString = '#team-members-block-' + blockID;
    $(blockIDString + ' .team-members-block__popup.visible').fadeOut(400, function () {

    });

    var teamMembers = $(blockIDString + ' .team-members-team_member');

    if (filter === 'all' || filter === undefined || filter === '') {
        teamMembers.removeClass('hidden-service');
    } else {
        teamMembers.removeClass('hidden-service');
        teamMembers.not('.' + filter).delay(400).addClass('hidden-service');
    }
    applyMarginClassesToTeamMembers();
};

var filterTeamMembersByPosition = function (filter, blockID) {
    // close all popups before filtering
    var blockIDString = '#team-members-block-' + blockID;
    $(blockIDString + ' .team-members-block__popup.visible').fadeOut(400, function () {

    });

    var teamMembers = $(blockIDString + ' .team-members-team_member');

    if (filter === 'all' || filter === undefined || filter === '') {
        teamMembers.removeClass('hidden-position');
    } else {
        teamMembers.removeClass('hidden-position');
        teamMembers.not('.' + filter).delay(400).addClass('hidden-position');
    }
    applyMarginClassesToTeamMembers();
};

var initialTeamMembersBlockFilterByPosition = function () {
    $('.team-members-block').each(function () {
        var block = $(this);
        var defaultFilter = block.attr('data-default-filter-position');
        filterTeamMembersByPosition(defaultFilter, block.attr('data-block-id'));
    });
};

var initialTeamMembersBlockFilterByService = function () {
    $('.team-members-block').each(function () {
        var block = $(this);
        var defaultFilter = block.attr('data-default-filter-service');
        filterTeamMembersByService(defaultFilter, block.attr('data-block-id'));
    });
};

var filterTestimonialsByService = function (filter) {
    var testimonials = $('.testimonial-grid__testimonial');

    if (filter === 'all') {
        testimonials.removeClass('hidden-testimonial');
    } else {
        testimonials.removeClass('hidden-testimonial');
        testimonials.not('.' + filter).delay(400).addClass('hidden-testimonial');
    }
    applyMarginClassesToTeamMembers();
};

$('#team-members-block__filter-service').change(function () {
    var filter = $(this).find(':selected').val();
    var id = $(this).attr('data-block-id');
    filterTeamMembersByService(filter, id);
});

$('#team-members-block__filter-position').change(function () {
    var filter = $(this).find(':selected').val();
    var id = $(this).attr('data-block-id');
    filterTeamMembersByPosition(filter, id);
});

$('#news-centre__news-filter-service').change(function () {
    var filter = $(this).find(':selected').val();
    filterNewsCentreSlider('.news-centre__news-slider', filter);
});

$('#news-centre__blog-filter-service').change(function () {
    var filter = $(this).find(':selected').val();
    filterNewsCentreSlider('.news-centre__blog-slider', filter);
});

$('#news-centre__video-filter-service').change(function () {
    var filter = $(this).find(':selected').val();
    filterNewsCentreSlider('.news-centre__video-slider', filter);
});

$('#news-centre__resources-filter-service').change(function () {
    var filter = $(this).find(':selected').val();
    filterNewsCentreSlider('.news-centre__resources-slider', filter);
});

$('#testimonial-filters__service-filter').change(function () {
    var service = $(this).find(':selected').val();
    filterTestimonialsByService(service);
});

var applyNewsCentreLatestFilter = function (filterName, filterValue) {
    var u = new URI();

    if (u.hasQuery(filterName)) {
        u.removeQuery(filterName);
    }
    u.addQuery(filterName, filterValue);
    window.location.href = u.toString();
};

$('#news-centre__resources-filter-title').change(function () {
    var filter = $(this).find(':selected').val();
    filterNewsCentreSlider('.news-centre__resources-slider', filter);
});

/**
 * @param sliderSelector
 * @param filter
 */
var filterNewsCentreSlider = function (sliderSelector, filter) {
    var slider = $(sliderSelector);
    slider.slick('slickUnfilter');
    if (filter !== 'all') {
        slider.slick('slickFilter', '.' + filter);
    }

    var noOfSlidesLeft = $(sliderSelector + ' .slick-track').children().length;

    if (noOfSlidesLeft <= 1) {
        $(sliderSelector).siblings('.news-centre__divider').addClass(
            'news-centre__divider--hidden'
        );
        if (noOfSlidesLeft === 0) {
            $(sliderSelector).siblings('.news-centre__news-slider-control').addClass(
                'news-centre__news-slider-control--hidden'
            );
        }
    } else {
        $(sliderSelector).siblings('.news-centre__divider').removeClass(
            'news-centre__divider--hidden'
        );
        $(sliderSelector).siblings('.news-centre__news-slider-control').removeClass(
            'news-centre__news-slider-control--hidden'
        );
    }
};

var activateSlider = function (classString, options) {
    $('.' + classString).each(function () {
        var element = $(this);
        var id = element.attr('data-block-id');

        if (options === undefined) {
            options = {};
        }

        var mergedOptions = $.extend({
            nextArrow: $('#right-control-' + id),
            prevArrow: $('#left-control-' + id),
            adaptiveHeight: true,
            accessibility: false,
        }, options);

        element.slick(mergedOptions);
    });
};

var triggerAnimation = function (element) {
    'use strict';

    //get the desired animation class from the data attribute
    var animationClass = element.attr('data-animation');

    //default animations to fadeIn
    if (animationClass === undefined) {
        animationClass = 'fadeIn';
    }
    //add the animation classes to begin the animation
    element.addClass('animated ' + animationClass);
};

var positionContactHover = function () {
    var hoverPanel = $('#hovering-contact');
    $('#hovering-contact-details__padding-td').css('width', hoverPanel.outerWidth() + 'px');
    var top = ($(window).outerHeight() / 2) - (hoverPanel.outerHeight() / 2);
    var hoverContactDetails = $('#hovering-contact-details');
    hoverPanel.css('top', top + 'px');

    var difference = hoverContactDetails.outerHeight() - hoverPanel.outerHeight();
    hoverContactDetails.css('top', top - (difference / 2) + 'px');
};

var triggerCountup = function (element) {
    var options = {
        useEasing: false,
        useGrouping: true,
        separator: ',',
        decimal: '.',
    };

    var countup = new CountUp('countup-' + element.attr('data-id'), 0, element.attr('data-countup'), 0, 3.325, options);
    if (!countup.error) {
        countup.start();
    }
};

/**
 * @returns {Array}
 */
var getUrlVars = function () {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
};

$(document).ready(function () {

    $('.masonry-grid').each(function () {
        var element = $(this);
        var id = element.data('id');

        var grid = element.imagesLoaded( function() {

            var msnry = new Masonry( '#masonry-grid-' + id, {
                itemSelector: '.masonry-grid__item',
                columnWidth: '.masonry-grid__sizer',
                percentPosition: true,
            });
        });
    });

    var contactPanelOpen = false;

    var closeContactPanel = function () {
        var hoverContactDetails = $('#hovering-contact-details');
        hoverContactDetails.css('right', '-' + hoverContactDetails.outerWidth() + 'px');
        $('.hovering-contact__down-icon').removeClass('hovering-contact__down-icon--rotated');
        contactPanelOpen = false;
    };

    var openContactPanel = function () {
        var hoverContactDetails = $('#hovering-contact-details');
        hoverContactDetails.css('right', '0px');
        $('.hovering-contact__down-icon').addClass('hovering-contact__down-icon--rotated');
        contactPanelOpen = true;
    };

    $('#hovering-contact__open-panel').click(function () {
        if (contactPanelOpen === true) {
            closeContactPanel();
        } else {
            openContactPanel();
        }
    });

    $('#hovering-contact-details__close-panel').click(function () {
        closeContactPanel();
    });

    positionContactHover();
    positionNavbarToggler();
    activateSlider('subservice-testimonials__slider', {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    activateSlider('news-centre__news-slider', {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: $('.right-control-news-news'),
        prevArrow: $('.left-control-news-news'),
    });
    activateSlider('news-centre__blog-slider', {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: $('.right-control-news-blog'),
        prevArrow: $('.left-control-news-blog'),
    });
    activateSlider('news-centre__resources-slider', {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: $('.right-control-news-resources'),
        prevArrow: $('.left-control-news-resources'),
    });
    activateSlider('news-centre__video-slider', {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        nextArrow: $('.right-control-news-video'),
        prevArrow: $('.left-control-news-video'),
    });

    initialTeamMembersBlockFilterByPosition();
    initialTeamMembersBlockFilterByService();

    // After all the above is done we can safely show the html
    $('html').css('visibility', 'visible');

    //window and animation items
    var animation_elements = $('.animation-element');
    var countups = $('.countup');

    //check to see if any animation containers are currently in view
    var checkIfInView = function () {

        // Do animations for animation elements
        $.each(animation_elements, function () {
            var element = $(this);
            if (elementIsInView(element) === true) {

                triggerAnimation(element);
                //remove the animation marker class so this element doesn't get picked up again
                element.removeClass('animation-element');
                //refresh the list of elements left to animate
                animation_elements = $.find('.animation-element');
            }
        });

        // Do countUps
        $.each(countups, function () {
            var element = $(this);
            if (elementIsInView(element) === true) {

                triggerCountup(element);
                element.removeClass('countup');
                countups = $.find('.countup');
            }
        });
    };

    var elementIsInView = function (element) {
        var web_window = $(window);
        var window_height = web_window.height();
        var window_top_position = web_window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);
        var element_height = $(element).outerHeight();
        var element_top_position = $(element).offset().top;
        var element_bottom_position = (element_top_position + element_height);

        return Boolean(
            (element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)
        );
    };

    //on or scroll, detect elements in view
    $(window).on('scroll resize', function () {
        checkIfInView();
        if ($(window).scrollTop() > 0) {
            $('#main-nav').addClass('scrolling');
        } else {
            $('#main-nav').removeClass('scrolling');
        }
    });

    //trigger our scroll event on initial load
    $(window).trigger('scroll');

    $(window).on('resize', function () {
        positionNavbarToggler();
    });

    var newsCentreData = {
        newsArticle: {
            link: $('#newsArticleJSONLink').text(),
            page: 1,
            requestInProgress: false,
            template: $('#newsArticleTemplate'),
            sortString: 'ns',
            loadMoreButton: $('.right-control-news-news'),
            sliderClass: '.news-centre__news-slider',
            replacements: {
                classesForFilter: /CLASSES_FOR_FILTER/g,
                publishDate: /PUBLISH_DATE/g,
                title: /TITLE/g,
                author: /AUTHOR/g,
                excerpt: /EXCERPT/g,
                link: /LINK/g,
            },
        },
        blogArticle: {
            link: $('#blogArticleJSONLink').text(),
            page: 1,
            requestInProgress: false,
            template: $('#newsArticleTemplate'),
            sortString: 'bs',
            loadMoreButton: $('.right-control-news-blog'),
            sliderClass: '.news-centre__blog-slider',
            replacements: {
                classesForFilter: /CLASSES_FOR_FILTER/g,
                publishDate: /PUBLISH_DATE/g,
                title: /TITLE/g,
                author: /AUTHOR/g,
                excerpt: /EXCERPT/g,
                link: /LINK/g,
            },
        },
    };

    var setupNewsCentreLoadMore = function (dataString) {
        newsCentreData[dataString].loadMoreButton.click(function () {
            // if the last slide is already visible
            if (newsCentreData[dataString].requestInProgress === false) {
                if ($(newsCentreData[dataString].sliderClass + ' .news-centre__news-slider-slide').last().hasClass('slick-active')) {
                    newsCentreData[dataString].requestInProgress = true;
                    newsCentreData[dataString].loadMoreButton.addClass('loading');

                    var currentURL = new URI();
                    var linkURL = new URI(newsCentreData[dataString].link);
                    var currentURLData = currentURL.search(true);

                    // re-add the new page variable to the new URL
                    linkURL.addQuery('page', newsCentreData[dataString].page);

                    // add the existing sort variable to the new URL
                    if (currentURLData[newsCentreData[dataString].sortString] !== undefined) {
                        linkURL.addQuery(
                            newsCentreData[dataString].sortString,
                            currentURLData[newsCentreData[dataString].sortString]
                        );
                    }

                    // add the existing year variable to the new URL
                    if (currentURLData['year'] !== undefined) {
                        linkURL.addQuery(
                            'year',
                            currentURLData['year']
                        );
                    }

                    var url = linkURL.toString();

                    // console.log(url);

                    $.ajax({
                        type: 'get',
                        url: url,
                        success: function (data) {

                            // console.log('data');
                            // console.log(data);

                            var responseJSON = JSON.parse(data);
                            // console.log('responseJSON');
                            // console.log(responseJSON);

                            var responseData = responseJSON.data;
                            // console.log('responseData');
                            // console.log(responseData);

                            for (var item in responseData) {
                                if (responseData.hasOwnProperty(item)) {
                                    var slideData = responseData[item];
                                    var newElement = newsCentreData[dataString].template.clone();
                                    var newHTML = newElement.html();
                                    var replacements = newsCentreData[dataString].replacements;

                                    for (var key in replacements) {
                                        if (responseData.hasOwnProperty(item)) {
                                            newHTML = newHTML.replace(replacements[key], slideData[key]);
                                        }
                                    }

                                    newElement.html(newHTML);
                                    $(newsCentreData[dataString].sliderClass).slick('slickAdd', newElement.html());
                                }
                            }
                            newsCentreData[dataString].page++;
                            newsCentreData[dataString].requestInProgress = false;
                            newsCentreData[dataString].loadMoreButton.removeClass('loading');
                        },
                    });
                }
            } else {
                // console.log('Already requested ' + dataString);
            }
        });
    };

    setupNewsCentreLoadMore('newsArticle');
    setupNewsCentreLoadMore('blogArticle');

});

$(function () {
    $('.submit-onchange').change(function () {
        $(this).closest('form').submit();
    });
});

$(window).on('load', function () {
    $('.google-map').each(function () {
        var location = {lat: parseFloat($(this).data('lat')), lng: parseFloat($(this).data('lng')),};
        var map = new google.maps.Map(this, {
            zoom: parseInt($(this).data('zoom')),
            center: location,
        });

        map.addListener('click', function () {
            window.open('https://www.google.com/maps/?q=' + location.lat + ',' + location.lng, '_blank');
        });

        var marker = new google.maps.Marker({
            icon: new google.maps.MarkerImage(
                '/themes/main/img/map-pointer.png',
                new google.maps.Size(181, 200),
                new google.maps.Point(0, 0),
                new google.maps.Point(88, 178)),
            position: location,
            map: map,
        });

        marker.addListener('click', function () {
            window.open('https://www.google.com/maps/?q=' + location.lat + ',' + location.lng, '_blank');
        });
    });
});
